export const certificateTypes = {
  FETCH_CERTIFICATES_REQUEST: "[CERTIFICATE] FETCH CERTIFICATES REQUEST",
  FETCH_CERTIFICATES_FINISH: "[CERTIFICATE] FETCH CERTIFICATES FINISH",
  FETCH_CERTIFICATES_FAIL: "[CERTIFICATE] FETCH CERTIFICATES FAIL",
  FETCH_CERTIFICATE_DETAIL_REQUEST:
    "[CERTIFICATE] FETCH CERTIFICATE DETAIL REQUEST",
  FETCH_CERTIFICATE_DETAIL_FINISH:
    "[CERTIFICATE] FETCH CERTIFICATE DETAIL FINISH",
  FETCH_CERTIFICATE_DETAIL_FAIL: "[CERTIFICATE] FETCH CERTIFICATE DETAIL FAIL",
};
