export const guidanceTypes = {
  BOOK_SESSION_REQUEST: "BOOK_SESSION_REQUEST",
  BOOK_SESSION_FINISH: "BOOK_SESSION_FINISH",
  BOOK_SESSION_FAIL: "BOOK_SESSION_FAIL",
  GUIDANCE_FILTER_REQUEST: "GUIDANCE_FILTER_REQUEST",
  GUIDANCE_FILTER_FINISH: "GUIDANCE_FILTER_FINISH",
  GUIDANCE_FILTER_FAIL: "GUIDANCE_FILTER_FAIL",
  GUIDANCE_CATEGORY_REQUEST: "GUIDANCE_CATEGORY_REQUEST",
  GUIDANCE_CATEGORY_FINISH: "GUIDANCE_CATEGORY_FINISH",
  GUIDANCE_CATEGORY_FINISH_PRIVATE: "GUIDANCE_CATEGORY_FINISH_PRIVATE",
  GUIDANCE_CATEGORY_FAIL: "GUIDANCE_CATEGORY_FAIL",
  GUIDANCE_CATEGORY_DETAIL_REQUEST: "GUIDANCE_CATEGORY_DETAIL_REQUEST",
  GUIDANCE_CATEGORY_DETAIL_FINISH: "GUIDANCE_CATEGORY_DETAIL_FINISH",
  GUIDANCE_CATEGORY_DETAIL_FAIL: "GUIDANCE_CATEGORY_DETAIL_FAIL",
  GUIDANCE_CATEGORY_DETAIL_RESET: "GUIDANCE_CATEGORY_DETAIL_RESET",
  GUIDANCE_START_USER_CAREER_TEST_REQUEST:
    "GUIDANCE_START_USER_CAREER_TEST_REQUEST",
  GUIDANCE_START_USER_CAREER_TEST_SUCCESS:
    "GUIDANCE_START_USER_CAREER_TEST_SUCCESS",
  GUIDANCE_START_USER_CAREER_TEST_FAIL: "GUIDANCE_START_USER_CAREER_TEST_FAIL",
  FETCH_GUIDANCE_START_USER_CAREER_TEST_REQUEST:
    "FETCH_GUIDANCE_START_USER_CAREER_TEST_REQUEST",
  FETCH_GUIDANCE_START_USER_CAREER_TEST_SUCCESS:
    "FETCH_GUIDANCE_START_USER_CAREER_TEST_SUCCESS",
  FETCH_GUIDANCE_START_USER_CAREER_TEST_FAIL:
    "GUIDANCE_START_USER_CAREER_TEST_FAIL",
  GUIDANCE_USER_CAREER_TEST_COUNT_REQUEST:
    "GUIDANCE_USER_CAREER_TEST_COUNT_REQUEST",
  GUIDANCE_USER_CAREER_TEST_COUNT_SUCCESS:
    "GUIDANCE_USER_CAREER_TEST_COUNT_SUCCESS",
  GUIDANCE_USER_CAREER_TEST_COUNT_FAIL: "GUIDANCE_USER_CAREER_TEST_COUNT_FAIL",
  POST_ANSWER_REQUEST : "POST_ANSWER_REQUEST",
  POST_ANSWER_FINISH : "POST_ANSWER_FINISH",
  POST_ANSWER_FAIL : "POST_ANSWER_FAIL",
  CAREER_RESULT_REQUEST : "CAREER_RESULT_REQUEST",
  CAREER_RESULT_FINISH : "CAREER_RESULT_FINISH",
  CAREER_RESULT_FAIL : "CAREER_RESULT_FAIL",
  CAREER_OPTIONS_REQUEST : "CAREER_OPTIONS_REQUEST",
  CAREER_OPTIONS_FINISH : "CAREER_OPTIONS_FINISH",
  CAREER_OPTIONS_FAIL : "CAREER_OPTIONS_FAIL",
};
