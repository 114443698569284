export const coursesTypes = {
  GOVERNMENT_EXAM_REQUEST: "GOVERNMENT_EXAM_REQUEST",
  GOVERNMENT_EXAM_FINISH: "GOVERNMENT_EXAM_FINISH",
  GOVERNMENT_EXAM_FAIL: "GOVERNMENT_EXAM_FAIL",
  TOP_COLLAGE_SEARCH_REQUEST: "TOP_COLLAGE_SEARCH_REQUEST",
  TOP_COLLAGE_SEARCH_FINISH: "TOP_COLLAGE_SEARCH_FINISH",
  TOP_COLLAGE_SEARCH_FAIL: "TOP_COLLAGE_SEARCH_FAIL",
  TOP_COLLAGE_REQUEST: "TOP_COLLAGE_REQUEST",
  TOP_COLLAGE_FINISH: "TOP_COLLAGE_FINISH",
  TOP_COLLAGE_FAIL: "TOP_COLLAGE_FAIL",
  TOP_SCHOOL_REQUEST: "TOP_SCHOOL_REQUEST",
  TOP_SCHOOL_FINISH: "TOP_SCHOOL_FINISH",
  TOP_SCHOOL_FAIL: "TOP_SCHOOL_FAIL",
  GOVERNMENT_EXAM_FILTER_SET: "GOVERNMENT_EXAM_FILTER_SET",
  GOVERNMENT_EXAM_FILTER_RESET: "GOVERNMENT_EXAM_FILTER_RESET"
};
