export const eventsTypes = {
    FETCH_EVENTS_REQUEST: "[EVENTS] FETCH REQUEST",
    FETCH_EVENTS_FINISH: "[EVENTS] FETCH FINISH",
    FETCH_EVENTS_FAIL: "[EVENTS] FETCH FAIL",
    BOOK_EVENT_REQUEST: "BOOK_EVENT_REQUEST",
    BOOK_EVENT_FINISH: "BOOK_EVENT_FINISH",
    BOOK_EVENT_FAIL: "BOOK_EVENT_FAIL",
    BOOK_EVENT_FINISH_CLOSE: "BOOK_EVENT_FINISH_CLOSE",
    GET_LOCAL_DATA: "GET_LOCAL_DATA",
    FETCH_Form: "FETCH_Form",
  };