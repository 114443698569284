export const authTypes = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FINISH: "LOGIN_FINISH",
  LOGIN_FAIL: "LOGIN_FAIL",
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_FINISH: "SIGNUP_FINISH",
  SIGNUP_FAIL: "SIGNUP_FAIL",
  CONTACT_VERIFY_REQUEST: "CONTACT_VERIFY_REQUEST",
  CONTACT_VERIFY_FINISH: "CONTACT_VERIFY_FINISH",
  CONTACT_VERIFY_FAIL: "CONTACT_VERIFY_FAIL",
  LOGOUT_USER: "LOGOUT_USER",
  REFRESH: "REFRESH",
  USER_PROFILE_REQUEST: "USER_PROFILE_REQUEST",
  USER_PROFILE_FINISH: "USER_PROFILE_FINISH",
  USER_PROFILE_FAIL: "USER_PROFILE_FAIL",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_FINISH: "RESET_PASSWORD_FINISH",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",
  USER_PROFILE_UPDATE: "USER_PROFILE_UPDATE",
  CONTACT_US_REQUEST : "CONTACT_US_REQUEST",
  CONTACT_US_FINISH : "CONTACT_US_FINISH",
  CONTACT_US_FAIL : "CONTACT_US_FAIL",
  FORGOT_PASSWORD_REQUEST : "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_FINISH : "FORGOT_PASSWORD_FINISH",
  FORGOT_PASSWORD_FAIL : "FORGOT_PASSWORD_FAIL",
};
