import * as React from "react";

import "./index.scss";

import LoginForm2 from "./LoginForm2";

export default function LoginTabs() {
  return (
    <>
      <LoginForm2 />
    </>
  );
}
