export const coursesTypes = {
  COURSES_REQUEST: "COURSES_REQUEST",
  COURSES_FINISH: "COURSES_FINISH",
  COURSES_FAIL: "COURSES_FAIL",
  TESTS_REQUEST: "TESTS_REQUEST",
  TESTS_FINISH: "TESTS_FINISH",
  TESTS_FAIL: "TESTS_FAIL",
  COURSE_REQUEST: "COURSE_REQUEST",
  COURSE_FINISH: "COURSE_FINISH",
  COURSE_FAIL: "COURSE_FAIL",
  ACCORDIAN_LIST_REQUEST: "ACCORDIAN_LIST_REQUEST",
  ACCORDIAN_LIST_FINISH: "ACCORDIAN_LIST_FINISH",
  ACCORDIAN_LIST_FAIL: "ACCORDIAN_LIST_FAIL",
  SIMILAR_COURSES_REQUEST: "SIMILAR_COURSES_REQUEST",
  SIMILAR_COURSES_FINISH: "SIMILAR_COURSES_FINISH",
  SIMILAR_COURSES_FAIL: "SIMILAR_COURSES_FAIL",
  TEST_QUEDTION_REQUEST: "TEST_QUEDTION_REQUEST",
  TEST_QUEDTION_FINISH: "TEST_QUEDTION_FINISH",
  TEST_QUEDTION_FAIL: "TEST_QUEDTION_FAIL",
  POST_ANSWER_REQUEST: "POST_ANSWER_REQUEST",
  POST_ANSWER_FINISH: "POST_ANSWER_FINISH",
  POST_ANSWER_FAIL: "POST_ANSWER_FAIL",
  QUESTION_COUNT_REQUEST: "QUESTION_COUNT_REQUEST",
  QUESTION_COUNT_FINISH: "QUESTION_COUNT_FINISH",
  QUESTION_COUNT_FAIL: "QUESTION_COUNT_FAIL",
  SUCCESS_STORY_REQUEST: "SUCCESS_STORY_REQUEST",
  SUCCESS_STORY_FINISH: "SUCCESS_STORY_FINISH",
  SUCCESS_STORY_FAIL: "SUCCESS_STORY_FAIL",
  //
  SUCCESS_STORY_DETAILS_REQUEST: "SUCCESS_STORY_DETAILS_REQUEST",
  SUCCESS_STORY_DETAILS_FINISH: "SUCCESS_STORY_DETAILS_FINISH",
  SUCCESS_STORY_DETAILS_FAIL: "SUCCESS_STORY_DETAILS_FAIL",
  
  HOME_COURSE_REQUEST: "HOME_COURSE_REQUEST",
  HOME_COURSE_FINISH: "HOME_COURSE_FINISH",
  HOME_COURSE_FAIL: "HOME_COURSE_FAIL",
  SELECTED_FILTER: "SELECTED_FILTER",
  CATEGORY_LIST_REQUEST: "CATEGORY_LIST_REQUEST",
  CATEGORY_LIST_FINISH: "CATEGORY_LIST_FINISH",
  CATEGORY_LIST_FAIL: "CATEGORY_LIST_FAIL",
  RESULT_REQUEST: "RESULT_REQUEST",
  RESULT_FINISH: "RESULT_FINISH",
  RESULT_FAIL: "RESULT_FAIL",
  CURRENT_MODAL:"CURRENT_MODAL",
};
